import React, { useState, lazy, Suspense, useEffect } from "react";
import { Route, Switch, withRouter, useHistory } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import * as jwt from "jsonwebtoken";

import PageRouters from "pages/PageRouters";
import NeuralXLoading from "components/NeuralXLoading";
import { currentPageCache } from "apollo/cache";
import { TRAINER_LOGIN_REFRESH_TOKEN, TRAINER_GET } from "services/trainer/queries";
import { ID_TOKEN_ATTRIBUTES, LOGIN_TOKENS } from "utils/constants/authentications";
import TRAINER_ATTRIBUTES from "utils/constants/trainer-attributes";
import STORAGE_NAMES from "utils/constants/storage-names";
import PAGES from "utils/links/pages";
import PAGE_NAMES from "utils/constants/page-names";
import { clearStorage, setStorage } from "utils/helpers/storage";

const LoginPage = lazy(() => import(/* webpackChunkName: 'login-page' */ "pages/LoginPage"));
const MusicStations = lazy(() => import(/* webpackChunkName: 'music-stations-page' */ "pages/MusicStationsPage"));
const PasswordResetPage = lazy(() => import(/* webpackChunkName: 'password-reset-page' */ "pages/PasswordResetPage"));
const VerificationLinkResendPage =
    lazy(() => (import(/* webpackChunkName: 'verification-link-resend-page' */ "pages/VerificationLinkResendPage")));

const App = () => {
    const history = useHistory();
    const [initializing, setInitializing] = useState(true);
    const [trainerLoginRefreshToken, {
        loading: loadingTrainerLoginRefreshToken,
        error: errorTrainerLoginRefreshToken
    }] = useLazyQuery(TRAINER_LOGIN_REFRESH_TOKEN, {
        onCompleted({ trainerLoginRefreshToken = {} }) {
            if (trainerLoginRefreshToken.success) {
                const idToken = trainerLoginRefreshToken[LOGIN_TOKENS.ID_TOKEN];

                const parsedIdToken = jwt.decode(idToken);
                const trainerId = parsedIdToken[ID_TOKEN_ATTRIBUTES.TRAINER_ID];
                const email = parsedIdToken[ID_TOKEN_ATTRIBUTES.EMAIL];
                const tier = parsedIdToken[ID_TOKEN_ATTRIBUTES.TIER];

                setStorage({ storageName: STORAGE_NAMES.SESSION, data: {
                    isLoggedIn: true,
                    trainerId,
                    email,
                    tier
                } });
                trainerGet({ variables: { [TRAINER_ATTRIBUTES.TRAINER_ID]: trainerId } });
                setInitializing(false);
            } else {
                setInitializing(false);
                clearStorage();
            }
        }
    });

    const [trainerGet, { loading: loadingTrainerGet, error: errorTrainerGet }] = useLazyQuery(TRAINER_GET, {
        onCompleted({ trainerGet = {} }) {
            if (trainerGet[TRAINER_ATTRIBUTES.IMAGE]) {
                setStorage({ storageName: STORAGE_NAMES.SESSION, data: { profileImage: trainerGet[TRAINER_ATTRIBUTES.IMAGE] } });
            }
            setStorage({ storageName: STORAGE_NAMES.SESSION, data: { isLoggedIn: true } });
            history.push(PAGES.HOME);
        }
    });

    useEffect(() => {
        if (errorTrainerLoginRefreshToken || errorTrainerGet) {
            clearStorage();
            setInitializing(false);
        }
    }, [errorTrainerLoginRefreshToken, errorTrainerGet]);

    // REF: Will be running this code snippet whenever the trainerLoginRefreshToken is changing
    useEffect(() => {
        const isLoggedIn = sessionStorage.getItem("isLoggedIn");

        if (isLoggedIn) {
            setInitializing(false);
        } else {
            const refreshToken = localStorage.getItem("trainerRefreshToken");
            if (refreshToken) {
                trainerLoginRefreshToken({ variables: { [LOGIN_TOKENS.REFRESH_TOKEN]: refreshToken } });
            } else {
                clearStorage();
                setInitializing(false);
            }
        }
    }, [trainerLoginRefreshToken]);

    useEffect(() => {
        const pathname = history.location.pathname;
        const pageInfo = Object.entries(PAGE_NAMES).find(value => {
            return value[1].link === pathname;
        });
        if (pageInfo && pageInfo.length) {
            currentPageCache(pageInfo[1].name);
        }
    }, [history.location.pathname]);

    if (initializing || loadingTrainerGet || loadingTrainerLoginRefreshToken) {
        return <NeuralXLoading wholeOverlay />;
    }

    return (
        <Suspense fallback={<NeuralXLoading wholeOverlay />}>
            <Switch>
                <Route
                    exact
                    path={PAGES.MUSIC_STATIONS}
                    render={props => <MusicStations {...props} />} />
                <Route
                    exact
                    path={PAGES.LOGIN}
                    render={props => <LoginPage {...props} />} />
                <Route
                    exact
                    path={PAGES.RESEND_VERIFICATION}
                    render={props => <VerificationLinkResendPage {...props} />} />
                <Route
                    exact
                    path={PAGES.RESET_PASSWORD}
                    render={props => <PasswordResetPage {...props} />} />
                <Route render={() => <PageRouters />} />
            </Switch>
        </Suspense>
    );
};

export default withRouter(App);
